import React from 'react';
import Icon from '@mdi/react';
import { mdiSend } from '@mdi/js';
import { Link } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import ReactMarkdown from "react-markdown";
function RouterLink(props) {
  return (
    props.href.match(/^(https?:)?\/\//)
      ? <a href={props.href}>{props.children}</a>
      : <Link to={props.href}>{props.children}</Link>
  );
}

var _contacts = ["ZHJiLm11czFAZ21haWwuY29t", "MDc5NzMgMzg0MjQw", "amVubmllLm1ja2F5QGhvdG1haWwuY28udWs=", "MDc3NzYgMjUxMTIw", "Z2NiaWxsaW5AaG90bWFpbC5jby51aw==", "MDc5NzEgOTE3NDE3"]

function Home({concerts,pagedata}) {
  var [name,setName] = React.useState('');
  var [email,setEmail] = React.useState('');
  var [msg,setMsg] = React.useState('');
  var [contacts,setContacts] = React.useState([]);
  var [first,setFirst] = React.useState(true);
  if(first) {
    setTimeout(()=>{
      setContacts(_contacts.map(atob))
    },1000)
    setFirst(false);
  }
  return !pagedata.contact ? <BounceLoader
    css={{display:"inline-block"}}
    size={100}
    color={"#123abc"}
    loading={true}
  /> : (
    <div style={{textAlign:"center",paddingLeft:8,paddingRight:8}} className="Page About">
      <div style={{display:"flex",width:'100%',fontSize:'1.2em',flexWrap:"wrap",flexDirection:"row"}}>
        {/* <div style={{fontSize:'1.6em',fontWeight:'bold'}}>Contacts</div> */}
        <div style={{width:'100%',marginTop:8}}>
          {/* Want to join the choir, or ask any questions? Feel free to contact us via any of the means below. */}
          <ReactMarkdown renderers={{link: RouterLink}} source={pagedata.contact}/>
        </div>
        {contacts.length>0 && <>
          <div style={{border:'1px solid black',borderRadius:8,padding:4,margin:4,flexGrow:1}}>
            <div style={{fontWeight:'bold',fontSize:'1.2em'}}>David Beavan</div>
            <div><a style={{color:"black"}} href={`mailto:${contacts[0]}`}>{contacts[0]}</a></div>
            <div><a style={{color:"black"}} href={`tel:${contacts[1].replace(/ /g,'')}`}>{contacts[1]}</a></div>
          </div>
          <div style={{border:'1px solid black',borderRadius:8,padding:4,margin:4,flexGrow:1}}>
            <div style={{fontWeight:'bold',fontSize:'1.2em'}}>Jennie McKay</div>
            <div><a style={{color:"black"}} href={`mailto:${contacts[2]}`}>{contacts[2]}</a></div>
            <div><a style={{color:"black"}} href={`tel:${contacts[3].replace(/ /g,'')}`}>{contacts[3]}</a></div>
          </div>
          <div style={{border:'1px solid black',borderRadius:8,padding:4,margin:4,flexGrow:1}}>
            <div style={{fontWeight:'bold',fontSize:'1.2em'}}>Georgie Billin</div>
            <div><a style={{color:"black"}} href={`mailto:${contacts[4]}`}>{contacts[4]}</a></div>
            <div><a style={{color:"black"}} href={`tel:${contacts[5].replace(/ /g,'')}`}>{contacts[5]}</a></div>
          </div>
        </>
        }
        <div style={{textAlign:"center",width:"100%",paddingBottom:8}}>
          <img style={{maxHeight:"40vmin",maxWidth:"100%",marginRight:8,border:"2px solid black",borderRadius:8}} src="/header_xxcompressed.jpg" />
        </div>
{/* Contacts:

David Beavan 07973 384240

drb.mus1@gmail.com

Jennie McKay 07776 251120

jennie.mckay@hotmail.co.uk

Georgie Billin 07971 917417  gcbillin@hotmail.co.uk */}
        {/* <p>
          Full Name<br/>
          <input className="Input" value={name} onChange={(e)=>{setName(e.target.value)}}/>
        </p>
        <p>
          Email<br/>
          <input className="Input" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
        </p>
        <p>
          Message<br/>
          <textarea style={{minHeight:200,minWidth:400}} className="Input" value={msg} onChange={(e)=>{setMsg(e.target.value)}}/>
        </p>
        <button className="MoreInfo" style={{paddingLeft:16,paddingRight:16}}>Send <Icon path={mdiSend} size="1em" style={{marginBottom:'-0.125em'}}/></button> */}
      </div>
    </div>
    
  );
}

export default Home;

import React from 'react';
import { Link } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
import ReactMarkdown from "react-markdown";
function RouterLink(props) {
  return (
    props.href.match(/^(https?:)?\/\//)
      ? <a href={props.href}>{props.children}</a>
      : <Link to={props.href}>{props.children}</Link>
  );
}

function Home({concerts,pagedata}) {
  return ( !pagedata.about || !pagedata.about_david ) ? <BounceLoader
    css={{display:"inline-block"}}
    size={100}
    color={"#123abc"}
    loading={true}
  /> : (
    <div style={{textAlign:"left",paddingLeft:8,paddingRight:8}} className="Page About">
      <div>
        {/* <p style={{fontWeight:"bold",fontSize:"1.2em"}}>
          About Rowell Camerata
        </p>
        <p>
          Rowell Camerata is a chamber choir based in Rothwell, Northamptonshire. Under the expert direction of David Beavan, the choir performs a wide range of  sacred and secular music with some contemporary repertoire to be performed with other ensembles.
        </p>
        <p>
          We are thrilled that we have been welcomed to make our “home” in the stunning church of <a href="https://goo.gl/maps/v93QNcKgTRvqeiFc8" rel="noopener noreferrer" target="_blank">
            Holy Trinity, Rothwell
          </a>. This is where we rehearse and where we perform several times a year.
        </p>
        <p>
          The choir comprises a mixture of experienced singers, novices and people who have returned to singing after a break. Therefore, whatever group you fall into, you will be extremely welcome. We have spaces in all parts but would especially welcome tenors and basses.
        </p>
        <p>
          Rehearsals are generally on Saturday mornings between 10:00 and 1:00 on a 3-weekly schedule at <a href="https://goo.gl/maps/v93QNcKgTRvqeiFc8" rel="noopener noreferrer" target="_blank">Holy Trinity Church, Rothwell</a>.
        </p>
        <p>
          Tempted to join? <Link to="/contact">Contact us!</Link>
        </p> */}
        <ReactMarkdown renderers={{link: RouterLink}} source={pagedata.about}/>
        <div style={{textAlign:"center",paddingBottom:8}}>
          <img style={{maxHeight:"40vmin",maxWidth:"100%",marginRight:8,border:"2px solid black",borderRadius:8}} src="/contact_compressed.jpg" />
        </div>
        <ReactMarkdown renderers={{link: RouterLink}} source={pagedata.about_david}/>
        {/* <p style={{fontWeight:"bold",fontSize:"1.2em"}}>
          About David Beavan
        </p>
        <p>
          David Beavan started his musical life as a choral scholar at Jesus College, Cambridge, followed by Trinity College of Music, London. There followed a long career performing and recording a wide range of musical styles from across the centuries. Work throughout the world included performances in the major opera houses, performances and recordings with the Hilliard Ensemble, Pro Cantione Antiqua, the Deller Consort and the Consort of Music.
        </p>
        <p>
          Working with Ward Swingle and the Swingle Singers for seven years was an important part of David’s experience, recording and performing on both the concert platform and TV all over the world.
        </p>
        <p>
          As a conductor, he directed several choirs in London, the Harborough Singers in Leicestershire as well as forming Serenata Voices, a group of young professional singers that performed regularly on national radio.
        </p>
        <p>
          David is delighted to have this opportunity to use his extensive experience to establish a choir in Rothwell and welcomes any potential singers to get in touch.
        </p> */}
        <div style={{textAlign:"center",width:"100%",paddingBottom:8}}>
          <img style={{maxHeight:"40vmin",maxWidth:"100%",marginRight:8,border:"2px solid black",borderRadius:8}} src="/about_compressed_large.jpg" />
        </div>
      </div>
    </div>
    
  );
}

export default Home;

import React from 'react';
import Icon from '@mdi/react';
import { mdiLockOpenOutline } from '@mdi/js';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BounceLoader from "react-spinners/BounceLoader";
function RouterLink(props) {
  return (
    props.href.match(/^(https?:)?\/\//)
      ? <a href={props.href}>{props.children}</a>
      : <Link to={props.href}>{props.children}</Link>
  );
}

function Home({concerts}) {
  var [password,setPassword] = React.useState('');
  var [input,setInput] = React.useState('');
  var [loading,setLoading] = React.useState('');

  async function getData() {
    setLoading(true);
    var data = await axios.get(`https://us-central1-choir-5664d.cloudfunctions.net/member?password=${encodeURIComponent(password)}`)
    console.log(data);
    setInput(data.data);
    setLoading(false);
  }
  return (
    <div style={{textAlign:"center",fontSize:'1em'}} className="Page">
      <div style={{width:'100%'}}>
        {
          (!input || input==="invalid") && <>
          <p style={{fontSize:'1.2em'}}>
            This page is password protected<br/>
            <input type="password" className="Input" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
          </p>
          <button onClick={()=>{getData()}} className="MoreInfo" style={{paddingLeft:16,paddingRight:16}}>Login <Icon path={mdiLockOpenOutline} size="1em" style={{marginBottom:'-0.125em'}}/></button>
          </>
        }
        
        {loading && <div style={{textAlign:"center",width:"100%",padding:16}}>
          <BounceLoader
            css={{display:"inline-block"}}
            size={100}
            color={"#123abc"}
            loading={loading}
          />
        </div>}
        {
          input && input!=="invalid" && <ReactMarkdown renderers={{link: RouterLink}} source={input}/>
        }
        {
          input && input==="invalid" && <div style={{color:"red"}}>Incorrect Password</div>
        }
      </div>
    </div>
    
  );
}

export default Home;

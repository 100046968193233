import React from 'react';
import Icon from '@mdi/react';
import { mdiClockOutline, mdiMapMarkerOutline, mdiLockOpenOutline, mdiUpload, mdiLock, mdiEmail, mdiHome, mdiCalendar, mdiMusic, mdiMenu, mdiClose } from '@mdi/js';
import moment from 'moment';
import axios from 'axios';
import BounceLoader from "react-spinners/BounceLoader";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/markdown/markdown'
import {Controlled as CodeMirror} from 'react-codemirror2'

function Admin({concerts,setConcerts,pagedata,setPagedata}) {
  var [locked,setLocked] = React.useState(true);
  var [loading,setLoading] = React.useState(false);
  var [password,setPassword] = React.useState("");
  var [memberData,setMemberData] = React.useState("");
  var [page,setPage] = React.useState("home")
  async function upload() {
    setLoading(true);
    setLocked(true);
    // var data = await axios.get(`https://us-central1-choir-5664d.cloudfunctions.net/setPagedata?password=${encodeURIComponent(password)}&pagedata=${encodeURIComponent(JSON.stringify({...pagedata,concerts:concerts}))}&member=${encodeURIComponent(memberData)}`);
    var data = await axios.post(`https://us-central1-choir-5664d.cloudfunctions.net/setPagedata`, {
      password: password,
      pagedata: JSON.stringify({...pagedata,concerts:concerts}),
      member: memberData
    })
    setLocked(false);
    window.location.href='/';
  }
  function setConcertData(index, value, data) {
    var x = concerts.slice();
    x[index][value] = data;
    setConcerts(x);
  }
  function setpd(value, data) {
    var x = {};
    x[value] = data;
    setPagedata({...pagedata,...x});
  }
  function removeEvent(index) {
    var x = concerts.slice();
    x.splice(index,1);
    setConcerts(x);
  }
  function addEvent() {
    var x = concerts.slice();
    x.push({
      title: "",
      day: "",
      month: 1,
      year: 2020,
      details: "",
      location: "Holy Trinity Church, Rothwell",
      location_link: "https://goo.gl/maps/v93QNcKgTRvqeiFc8"
    });
    setConcerts(x);
  }
  async function getData() {
    setLoading(true);
    var data = await axios.get(`https://us-central1-choir-5664d.cloudfunctions.net/member?admin&password=${encodeURIComponent(password)}`);
    if(data.data=="invalid") {
      setLocked("invalid")
    } else {
      setLocked(false)
      setMemberData(data.data);
    }
    setLoading(false);
  }
  return (
    <div className="Page About">
      <div style={{width:'100%'}}>
        {
          locked && !loading && <>
          <p style={{fontSize:'1.2em'}}>
            Admin Password<br/>
            <input type="password" className="Input" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
          </p>
          <button onClick={()=>{getData()}} className="MoreInfo" style={{paddingLeft:16,paddingRight:16}}>Login <Icon path={mdiLockOpenOutline} size="1em" style={{marginBottom:'-0.125em'}}/></button>
          </>
        }
        { locked=="invalid" && <div style={{color:"red"}}>Incorrect Password</div> }
        {loading && <div style={{textAlign:"center",width:"100%",padding:16}}>
          <BounceLoader
            css={{display:"inline-block"}}
            size={100}
            color={"#123abc"}
            loading={loading}
          />
        </div>}
        { !locked && <>
          <button onClick={()=>{setPage("home")}} className="MoreInfo" style={{paddingLeft:4,paddingRight:4}}>Home <Icon path={mdiHome} size="1em" style={{marginBottom:'-0.125em'}}/></button>
          <button onClick={()=>{setPage("about")}} className="MoreInfo" style={{paddingLeft:4,paddingRight:4}}>About Us <Icon path={mdiMusic} size="1em" style={{marginBottom:'-0.125em'}}/></button>
          <button onClick={()=>{setPage("concerts")}} className="MoreInfo" style={{paddingLeft:4,paddingRight:4}}>Concerts <Icon path={mdiCalendar} size="1em" style={{marginBottom:'-0.125em'}}/></button>
          <button onClick={()=>{setPage("contact")}} className="MoreInfo" style={{paddingLeft:4,paddingRight:4}}>Contact <Icon path={mdiEmail} size="1em" style={{marginBottom:'-0.125em'}}/></button>
          <button onClick={()=>{setPage("member")}} className="MoreInfo" style={{paddingLeft:4,paddingRight:4}}>Members <Icon path={mdiLock} size="1em" style={{marginBottom:'-0.125em'}}/></button>
        </> }
        { !locked && page=="member" && <div style={{fontSize:'1.4em',marginTop:8}}>Member Page Content (Markdown)</div> }
        {/* { !locked && page=="member" && <textarea style={{fontFamily:'monospace',minWidth:'90%',minHeight:'40vh'}} className="Input" value={memberData} onChange={(e)=>{setMemberData(e.target.value)}}/> } */}
        { !locked && page=="member" && <div style={{textAlign:"left"}}><CodeMirror
          style={{textAlign:"left"}}
          value={memberData}
          options={{
            mode: 'markdown',
            lineNumbers: true,
            lineWrapping: true
          }}
          onBeforeChange={(editor, data, value) => {
            setMemberData(value);
          }}
        /></div>}
        { !locked && page=="home" && <div style={{fontSize:'1.4em',marginTop:8}}>Home Page Content (Markdown)</div> }
        {/* { !locked && page=="home" && <textarea style={{fontFamily:'monospace',minWidth:'90%',minHeight:'40vh'}} className="Input" value={pagedata.home} onChange={(e)=>{setpd("home",e.target.value)}}/> } */}
        { !locked && page=="home" && <div style={{textAlign:"left"}}><CodeMirror
          style={{textAlign:"left"}}
          value={pagedata.home}
          options={{
            mode: 'markdown',
            lineNumbers: true,
            lineWrapping: true
          }}
          onBeforeChange={(editor, data, value) => {
            setpd("home",value);
          }}
        /></div>}
        { !locked && page=="contact" && <div style={{fontSize:'1.4em',marginTop:8}}>Contact Page Content (Markdown)</div> }
        {/* { !locked && page=="contact" && <textarea style={{fontFamily:'monospace',minWidth:'90%',minHeight:'40vh'}} className="Input" value={pagedata.contact} onChange={(e)=>{setpd("contact",e.target.value)}}/> } */}
        { !locked && page=="contact" && <div style={{textAlign:"left"}}><CodeMirror
          style={{textAlign:"left"}}
          value={pagedata.contact}
          options={{
            mode: 'markdown',
            lineNumbers: true,
            lineWrapping: true
          }}
          onBeforeChange={(editor, data, value) => {
            setpd("contact",value);
          }}
        /></div>}
        { !locked && page=="about" && <div style={{fontSize:'1.4em',marginTop:8}}>About Page Content (Markdown)</div> }
        {/* { !locked && page=="about" && <textarea style={{fontFamily:'monospace',minWidth:'90%',minHeight:'40vh'}} className="Input" value={pagedata.about} onChange={(e)=>{setpd("about",e.target.value)}}/> } */}
        { !locked && page=="about" && <div style={{textAlign:"left"}}><CodeMirror
          style={{textAlign:"left"}}
          value={pagedata.about}
          options={{
            mode: 'markdown',
            lineNumbers: true,
            lineWrapping: true
          }}
          onBeforeChange={(editor, data, value) => {
            setpd("about",value);
          }}
        /></div>}
        { !locked && page=="about" && <div style={{fontSize:'1.4em',marginTop:8}}>About David Content (Markdown)</div> }
        {/* { !locked && page=="about" && <textarea style={{fontFamily:'monospace',minWidth:'90%',minHeight:'40vh'}} className="Input" value={pagedata.about_david} onChange={(e)=>{setpd("about_david",e.target.value)}}/> } */}
        { !locked && page=="about" && <div style={{textAlign:"left"}}><CodeMirror
          style={{textAlign:"left"}}
          value={pagedata.about_david}
          options={{
            mode: 'markdown',
            lineNumbers: true,
            lineWrapping: true
          }}
          onBeforeChange={(editor, data, value) => {
            setpd("about_david",value);
          }}
        /></div>}
        { !locked && page=="concerts" && <div style={{fontSize:'1.4em',marginTop:8}}>Concerts</div> }
        {!locked && page=="concerts" && concerts.map((c,i) => (
          <div key={i} style={{padding:8}}>
            <div style={{border:'1px solid black',borderRadius:8,padding:4,display:"flex",flexDirection:"column"}}>
              <div style={{display:"flex",flexDirection:"row"}}>
                <div style={{fontSize:'2em',minWidth:'95px',justifyContent:"center",lineHeight:0.9,textTransform:"uppercase",opacity:0.8,display:"flex",flexDirection:"row",alignItems:"center"}}>
                  {['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][c.month]||'Jan'}{ !c.day.match(/[^0-9]/) && <><br/>
                  {c.day}</>
                }
                </div>
                <div style={{textAlign:"left",padding:4}}>
                  <div style={{fontSize:'1.3em',fontWeight:'bold'}}>{c.title}</div>
                  <div style={{opacity:0.8}}>
                    <Icon path={mdiClockOutline} size="1em" style={{marginBottom:'-0.125em'}}/>
                    {/* {moment(c.time).format('Do MMM LT')} */}
                    {c.day||1} {['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][c.month]||'Jan'} {c.year||2020}
                  </div>
                  <div><a style={{color:'black'}} href={c.location_link||undefined}>
                    <Icon path={mdiMapMarkerOutline} size="1em" style={{marginBottom:'-0.125em'}}/>
                    {c.location}
                  </a></div>
                </div>
              </div>
              <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                <input style={{marginRight:4}} className="Input" placeholder="Day" value={c.day} onChange={(e)=>{setConcertData(i,"day",e.target.value)}}/>
                <select style={{marginRight:4}} className="Input" value={c.month} onChange={(e)=>{setConcertData(i,"month",Number(e.target.value))}}>
                  <option value="">Month</option>
                  <option value="0">January</option>
                  <option value="1">February</option>
                  <option value="2">March</option>
                  <option value="3">April</option>
                  <option value="4">May</option>
                  <option value="5">June</option>
                  <option value="6">July</option>
                  <option value="7">August</option>
                  <option value="8">September</option>
                  <option value="9">October</option>
                  <option value="10">November</option>
                  <option value="11">December</option>
                </select>
                <input className="Input" placeholder="Year" value={c.year} type="number" onChange={(e)=>{setConcertData(i,"year",e.target.value)}}/>
              </div>
              {/* <input className="Input" type="number" placeholder="Month" value={c.month||1} onChange={(e)=>{setConcertData(i,"month",Number(e.target.value)>12?12:e.target.value)}}/> */}
              <input className="Input" placeholder="Title" value={c.title} onChange={(e)=>{setConcertData(i,"title",e.target.value)}}/>
              <input className="Input" placeholder="Location" value={c.location} onChange={(e)=>{setConcertData(i,"location",e.target.value)}}/>
              <input className="Input" placeholder="Location Link (Optional)" value={c.location_link} onChange={(e)=>{setConcertData(i,"location_link",e.target.value)}}/>
              <textarea style={{minWidth:'90%',minHeight:'100px'}} className="Input" placeholder="Details" value={c.details} onChange={(e)=>{setConcertData(i,"details",e.target.value)}}/>
              <button onClick={()=>{removeEvent(i)}} className="MoreInfo" style={{paddingLeft:16,paddingRight:16,borderColor:"red",color:"red"}}>Remove</button>
            </div>
          </div>
        ))}
        { !locked && page=="concerts" && <><button onClick={()=>{addEvent()}} className="MoreInfo" style={{paddingLeft:16,paddingRight:16}}>Add Concert</button><br/></> }
        { !locked && <button onClick={()=>{upload()}} className="MoreInfo" style={{paddingLeft:16,paddingRight:16}}>Upload Data to Site <Icon path={mdiUpload} size="1em" style={{marginBottom:'-0.125em'}}/></button> }
      </div>
    </div>
    
  );
}

export default Admin;

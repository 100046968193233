import React from 'react';
import {
  Link
} from "react-router-dom";
import Icon from '@mdi/react';
import { mdiClockOutline, mdiMapMarkerOutline } from '@mdi/js';
import moment from 'moment';
import BounceLoader from "react-spinners/BounceLoader";
import ReactMarkdown from "react-markdown";
function RouterLink(props) {
  return (
    props.href.match(/^(https?:)?\/\//)
      ? <a href={props.href}>{props.children}</a>
      : <Link to={props.href}>{props.children}</Link>
  );
}

function Home({concerts,pagedata}) {
  return !pagedata.home ? <BounceLoader
    css={{display:"inline-block"}}
    size={100}
    color={"#123abc"}
    loading={true}
  /> : (
    <div className="Page">
      <div className="Main" style={{textAlign:"left",paddingLeft:8,paddingRight:8}}>
        <ReactMarkdown renderers={{link: RouterLink}} source={pagedata.home}/>
        {/* <p>
          Rowell Camerata is a chamber choir based in Rothwell, Northamptonshire. We perform a wide range of sacred and secular music with some contemporary repertoire to be performed with other ensembles.
        </p>
        <p>
          We rehearse in the stunning church of <a href="https://goo.gl/maps/v93QNcKgTRvqeiFc8" rel="noopener noreferrer" target="_blank">
            Holy Trinity, Rothwell
          </a>, and we perform here several times a year.
        </p>
        <p>
          The choir comprises a mixture of experienced singers, novices and people who have returned to singing after a break. Therefore, whatever group you fall into, you will be extremely welcome. We have spaces in all parts but would especially welcome tenors and basses.
        </p>
        <p>
          Rehearsals are generally on Saturday mornings between 10:00 and 1:00 on a 3-weekly schedule at <a href="https://goo.gl/maps/v93QNcKgTRvqeiFc8" rel="noopener noreferrer" target="_blank">Holy Trinity Church, Rothwell</a>.
        </p>
        <p>
          Tempted to join? <Link to="/contact">Contact us!</Link>
        </p> */}
        <div style={{textAlign:"center"}}>
          <img style={{maxHeight:'40vmin',maxWidth:'100%',marginBottom:8,border:"2px solid black",borderRadius:8}} src="/home_compressed.jpg"/>
        </div>
      </div>
      <div className="Side">
        <div style={{textAlign:"center",fontSize:'1.6em',width:"100%",paddingTop:8}}>
          Upcoming Concerts
        </div>
        {concerts.length==0 && <div style={{textAlign:"center",width:"100%",padding:16}}>
          <BounceLoader
            css={{display:"inline-block"}}
            size={100}
            color={"#123abc"}
            loading={concerts.length==0}
          />
        </div>}
        {concerts.filter(c=>!c.past).slice(0,3).map((c,i) => (
          <div key={i} style={{padding:8}}>
            <div style={{border:'1px solid black',borderRadius:8,padding:4,display:"flex",flexDirection:"row"}}>
              <div style={{fontSize:'2em',minWidth:'95px',justifyContent:"center",lineHeight:0.9,textTransform:"uppercase",opacity:0.8,display:"flex",flexDirection:"row",alignItems:"center"}}>
                {['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][c.month]||'Jan'}{ !c.day.match(/[^0-9]/) && <><br/>
                  {c.day}</>
                }
              </div>
              <div style={{textAlign:"left",padding:4}}>
                <div style={{fontSize:'1.3em',fontWeight:'bold'}}>{c.title}</div>
                <div style={{opacity:0.8}}>
                  <Icon path={mdiClockOutline} size="1em" style={{marginBottom:'-0.125em'}}/>
                  {/* {moment(c.time).format('Do MMM LT')} */}
                  {c.day||1} {['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][c.month]||'Jan'} {c.year||2020}
                </div>
                <div><a style={{color:'black'}} href={c.location_link||undefined}>
                  <Icon path={mdiMapMarkerOutline} size="1em" style={{marginBottom:'-0.125em'}}/>
                  {c.location}
                </a></div>
                <Link to="/concerts">
                  <button className="MoreInfo">More Info</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    
  );
}

export default Home;

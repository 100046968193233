import React from 'react';
import './App.css';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Link
} from "react-router-dom";
import Icon from '@mdi/react';
import { mdiLock, mdiEmail, mdiHome, mdiCalendar, mdiMusic, mdiMenu, mdiClose } from '@mdi/js';
import Home from './Pages/Home'
import Contact from './Pages/Contact'
import Concerts from './Pages/Concerts'
import Members from './Pages/Members'
import About from './Pages/About'
import Admin from './Pages/Admin'
import axios from 'axios';


function App() {
  var [menu,setMenu] = React.useState(false);
  var [concerts,setConcerts] = React.useState([]);
  var [pagedata,setPagedata] = React.useState({});
  var [concertsLoaded,setConcertsLoaded] = React.useState(false);
  (async ()=>{
    if(!concertsLoaded) {
      setConcertsLoaded(true);
      var {data} = await axios.get('https://us-central1-choir-5664d.cloudfunctions.net/pagedata')
      setConcerts(data.concerts);
      setPagedata({
        ...data,
        concerts: undefined
      });
    }
  })()
  var history = useHistory();
  var location = useLocation();
  return (
    <div className="App" style={{display:"flex",flexDirection:"column",minHeight:"100vh"}}>
      {/* <header style={{display:"flex",flexDirection:"row",flexWrap:"wrap",alignItems:"center",justifyContent: "flex-start",background: `#000 url(https://munzee.global.ssl.fastly.net/images/site/home/mine.jpg)`,backgroundSize:"cover",backgroundRepeat: 'no-repeat',height:'30vmin'}}>
        <div style={{verticalAlign:"middle",backgroundColor:"#000a",width:"100%",color:"white",fontSize:"10vmin"}}>Choir Name</div>
        <div style={{verticalAlign:"middle",backgroundColor:"#000a",width:"100%",color:"white",fontSize:"3vmin"}}>
          <div onClick={()=>{setTab(0)}} className={`Tab ${tab===0&&"TabSelected"}`}>Home</div>
          <div onClick={()=>{setTab(1)}} className={`Tab ${tab===1&&"TabSelected"}`}>About Us</div>
          <div onClick={()=>{setTab(2)}} className={`Tab ${tab===2&&"TabSelected"}`}>Concerts</div>
          <div onClick={()=>{setTab(3)}} className={`Tab ${tab===3&&"TabSelected"}`}>Contact Us</div>
        </div>
      </header><br/><br/> */}
      <div style={{flexGrow:1}}>
        <header className="Header">
          <div className="HeaderTitle">Rowell Camerata</div>
          {/* <span>Camerata</span> */}
          <div className={menu?'MenuOpen':'MenuNotOpen'} style={{transition:'all 0.2s linear',backgroundColor:menu?'#000f':"#000a",width:"100%",color:"white",fontSize:"20px",position:"absolute",bottom:0}}>
            <div onClick={()=>{setMenu(!menu)}} className={`Tab TabMobile`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={menu?mdiClose:mdiMenu} title="Menu"/></span></span>
            </div>
            <div onClick={()=>{setMenu(false);history.push('/')}} className={`Tab ${location.pathname==='/'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiHome} title="Home"/> Home</span></span>
            </div>
            <div onClick={()=>{setMenu(false);history.push('/about')}} className={`Tab ${location.pathname==='/about'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiMusic} title="About Us"/> About Us</span></span>
            </div>
            <div onClick={()=>{setMenu(false);history.push('/concerts')}} className={`Tab ${location.pathname==='/concerts'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiCalendar} title="Concerts"/> Concerts</span></span>
            </div>
            <div onClick={()=>{setMenu(false);history.push('/contact')}} className={`Tab ${location.pathname==='/contact'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiEmail} title="Contact"/> Contact</span></span>
            </div>
            <div onClick={()=>{setMenu(false);history.push('/members')}} className={`Tab ${location.pathname==='/members'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiLock} title="Members"/> Members</span></span>
            </div>
          </div>
          <div className={`MobileBar ${menu?'Open':''}`}>
            <div onClick={()=>{setMenu(false);history.push('/')}} className={`Tab ${location.pathname==='/'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiHome} title="Home"/> Home</span></span>
            </div>
            <div onClick={()=>{setMenu(false);history.push('/about')}} className={`Tab ${location.pathname==='/about'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiMusic} title="About Us"/> About Us</span></span>
            </div>
            <div onClick={()=>{setMenu(false);history.push('/concerts')}} className={`Tab ${location.pathname==='/concerts'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiCalendar} title="Concerts"/> Concerts</span></span>
            </div>
            <div onClick={()=>{setMenu(false);history.push('/contact')}} className={`Tab ${location.pathname==='/contact'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiEmail} title="Contact"/> Contact</span></span>
            </div>
            <div onClick={()=>{setMenu(false);history.push('/members')}} className={`Tab ${location.pathname==='/members'&&"TabSelected"}`}>
              <span className="TabInner"><span><Icon style={{height:'1em',marginBottom:'-0.125em'}} color="white" path={mdiLock} title="Members"/> Members</span></span>
            </div>
          </div>
        </header>
        <div
          // style={{filter:'invert(1)',backgroundColor:'white'}}
        >
          <Switch>
            <Route exact path="/">
              <Home pagedata={pagedata} concerts={concerts}/>
            </Route>
            <Route path="/about">
              <About pagedata={pagedata}/>
            </Route>
            <Route path="/concerts">
              <Concerts pagedata={pagedata} concerts={concerts}/>
            </Route>
            <Route path="/contact">
              <Contact pagedata={pagedata}/>
            </Route>
            <Route path="/members">
              <Members pagedata={pagedata}/>
            </Route>
            <Route path="/admin">
              <Admin concerts={concerts} pagedata={pagedata} setConcerts={setConcerts} setPagedata={setPagedata}/>
            </Route>
          </Switch>
        </div>
      </div>
      <footer style={{flexDirection:"row",flexWrap: "wrap",alignItems: "stretch",display:"flex",backgroundColor:'#000d',color:"white",padding:16}}>
        <div style={{width:'100%',flexGrow:1,textAlign:"left"}}>
          Site designed by <a style={{color:"white"}} href="https://sohcah.dev/">sohcah</a>
        </div>
        {/* <div style={{width:'100%',flexGrow:1,textAlign:"left"}}>
          <img alt="facebook" src="f_logo_RGB-Blue_100.png" style={{height:'2em'}}/>
        </div> */}
        <div style={{width:'100%',flexGrow:1,textAlign:"left"}}>
          <Link style={{color:"white"}} to="/admin">Site Admin</Link>
        </div>
      </footer>
    </div>
  );
}

export default App;

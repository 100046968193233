import React from 'react';
import Icon from '@mdi/react';
import { mdiClockOutline, mdiMapMarkerOutline } from '@mdi/js';
import moment from 'moment';
import BounceLoader from "react-spinners/BounceLoader";

function Home({concerts}) {
  return (
    <div className="Page About">
      <div style={{width:'100%'}}>
        <div style={{textAlign:"center",fontSize:'1.6em',width:"100%",paddingTop:8}}>
          Upcoming Concerts
        </div>
        {concerts.length==0 && <div style={{textAlign:"center",width:"100%",padding:16}}>
          <BounceLoader
            css={{display:"inline-block"}}
            size={100}
            color={"#123abc"}
            loading={concerts.length==0}
          />
        </div>}
        {concerts.filter(c=>!c.past).map((c,i) => (
          <div key={i} style={{padding:8}}>
            <div style={{border:'1px solid black',borderRadius:8,padding:4,display:"flex",flexDirection:"column"}}>
              <div style={{display:"flex",flexDirection:"row"}}>
                <div style={{fontSize:'2em',minWidth:'95px',justifyContent:"center",lineHeight:0.9,textTransform:"uppercase",opacity:0.8,display:"flex",flexDirection:"row",alignItems:"center"}}>
                  {['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][c.month]||'Jan'}{ !c.day.match(/[^0-9]/) && <><br/>
                  {c.day}</>
                }
                </div>
                <div style={{textAlign:"left",padding:4}}>
                  <div style={{fontSize:'1.3em',fontWeight:'bold'}}>{c.title}</div>
                  <div style={{opacity:0.8}}>
                    <Icon path={mdiClockOutline} size="1em" style={{marginBottom:'-0.125em'}}/>
                    {/* {moment(c.time).format('Do MMM LT')} */}
                    {c.day||1} {['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][c.month]||'Jan'} {c.year||2020}
                  </div>
                  <div><a style={{color:'black'}} href={c.location_link||undefined}>
                    <Icon path={mdiMapMarkerOutline} size="1em" style={{marginBottom:'-0.125em'}}/>
                    {c.location}
                  </a></div>
                </div>
              </div>
              <div style={{width:"100%",textAlign:"left"}}>
                {c.details}
              </div>
            </div>
          </div>
        ))}
        <div style={{textAlign:"center",fontSize:'1.6em',width:"100%",paddingTop:8}}>
          Past Concerts
        </div>
        {concerts.length==0 && <div style={{textAlign:"center",width:"100%",padding:16}}>
          <BounceLoader
            css={{display:"inline-block"}}
            size={100}
            color={"#123abc"}
            loading={concerts.length==0}
          />
        </div>}
        {concerts.filter(c=>c.past).reverse().map((c,i) => (
          <div key={i} style={{padding:8}}>
            <div style={{border:'1px solid black',borderRadius:8,padding:4,display:"flex",flexDirection:"column"}}>
              <div style={{display:"flex",flexDirection:"row"}}>
                <div style={{fontSize:'2em',minWidth:'95px',justifyContent:"center",lineHeight:0.9,textTransform:"uppercase",opacity:0.8,display:"flex",flexDirection:"row",alignItems:"center"}}>
                  {['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][c.month]||'Jan'}{ !c.day.match(/[^0-9]/) && <><br/>
                  {c.day}</>
                }
                </div>
                <div style={{textAlign:"left",padding:4}}>
                  <div style={{fontSize:'1.3em',fontWeight:'bold'}}>{c.title}</div>
                  <div style={{opacity:0.8}}>
                    <Icon path={mdiClockOutline} size="1em" style={{marginBottom:'-0.125em'}}/>
                    {/* {moment(c.time).format('Do MMM LT')} */}
                    {c.day||1} {['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][c.month]||'Jan'} {c.year||2020}
                  </div>
                  <div><a style={{color:'black'}} href={c.location_link||undefined}>
                    <Icon path={mdiMapMarkerOutline} size="1em" style={{marginBottom:'-0.125em'}}/>
                    {c.location}
                  </a></div>
                </div>
              </div>
              <div style={{width:"100%",textAlign:"left"}}>
                {c.details}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    
  );
}

export default Home;
